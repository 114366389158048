import "../styles/MentionsLegales.scss";

import Footer from "../components/Footer.jsx";
import Logo from "../components/Logo.jsx";
import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";

function MentionsLegales({ data }) {
  const markdownFromContentful =
    data.allContentfulPageMentionsLegales.edges[0].node.mentionsLegales
      .mentionsLegales;

  return (
    <div className="mentionslegales-container">
      <Logo />
      <div className="text-container">
        <div className="text-inner">
          <ReactMarkdown
            source={markdownFromContentful}
            className="reactMarkDown"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export const query = graphql`
  query MentionsLegalesQuery {
    allContentfulPageMentionsLegales {
      edges {
        node {
          mentionsLegales {
            mentionsLegales
          }
        }
      }
    }
  }
`;

export default MentionsLegales;
